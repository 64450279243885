import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  //mode: 'history',
  routes: [{
    path: '/',
    name: 'index',
    meta: '首页-授渔金控_授渔SAAS助贷系统_银行贷款中介CRM客户管理系统代理_金融贷款业务获客系统',
    component: () => import('@/views/index')
  }, {
    path: '/news',
    name: 'news',
    meta: '常见问题-授渔金控_授渔SAAS助贷系统_银行贷款中介CRM客户管理系统代理_金融贷款业务获客系统_四象联创',
    component: () => import('@/views/news')
  }
  ]
})
